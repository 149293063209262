import React from 'react';
import './styles/form-section.scss';

const FormSection = ({ title, extra, children, className, style }) => {
  return (
    <div className={`form-section poppins ${className || ''}`} style={style}>
      <div className="form-section-header d-flex jc-space-between ai-center">
        <h3 className="fw-600">{title}</h3>
        <div>{extra}</div>
      </div>
      <div className="form-section-content">{children}</div>
    </div>
  );
};

export default FormSection;
